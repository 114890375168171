import { qwebApi } from '@/apis/qwebApi'
// import { getTrackId } from '@/tool/track'

// Stat event type
export const STATS_POINT = {
  BSX_JUMP_TO_DETAIL_PAGE: 'App_JumpToDetailPage',
  BSX_SEARCH_IN_GP_CLICKED: 'SearchInGP_Clicked',
  BSX_GPSTORE_CLICKED: 'GPStore_Clicked',
  BSX_GPPAGE_VIEW_ALL_GAMES: 'ViewAllGames_Clicked',
}

// Stat table
export const TABLE_TYPE = {
  LAUNCHER_APP_CLICK: 'launcher_app_click_stats',
  LAUNCHER_APP_CLICK_V2: 'launcher_app_click_statsV2',
}

/**
 * Send a stat
 * @param {string} tableType Stat Table
 * @param {object} values Stat data
 */
const stats = async (tableType, values = {}) => {
  // Add track ID here
  // getTrackId()
  const _values = {}
  for (let key in values) {
    const value = values[key]
    if (typeof value === 'function') {
      try {
        _values[key] = await value(key)
      } catch (error) { }
    } else {
      _values[key] = value
    }
  }
  qwebApi({
    event: 'postStats',
    data: {
      table: tableType,
      data: _values,
    },
  })
}

Object.defineProperty(stats, 'TABLE_TYPE', {
  value: TABLE_TYPE,
  writable: false,
})

Object.defineProperty(stats, 'STATS_POINT', {
  value: STATS_POINT,
  writable: false,
})

export default stats
