import React, { useCallback } from 'react'
import classNames from 'classnames'
import AspectImage from '@/components/common/Image/AspectImage'
import { onGameClickAction } from '@/actions/commonActions'
import useNarrorClick from '@/hook/useNarrorClick'
import './slider.scss'
const Slider = ({ app, bannerLocation, className, tabIndex = 0, tabId }) => {
  // 点击游戏按钮
  const handleOnClick = useCallback(
    (evt) => {
      onGameClickAction({
        app,
        isInstalled: false,
        evt,
        gameType: 'simulator',
        bannerLocation,
        redirectCode: 'AppLauncher',
      })
    },
    [bannerLocation, app]
  )

  const eventProps = useNarrorClick(handleOnClick)

  return (
    <div
      className={classNames(['slider-container', className])}
      {...eventProps}
      id={tabId}
      tabIndex={tabIndex}
    >
      <AspectImage src={app.bg_image} objectFit="fill" aspectClass="ratio-16-9" />
    </div>
  )
}

export default Slider
