import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import RcTooltip from '@/components/tooltip/RcTooltip'
import { FormattedMessage, useIntl } from 'react-intl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Button from '@/components/common/Button/Button'
import CloudButton from '@/components/adapter/CloudButton'
import ButtonGroup from '@/components/common/Button/ButtonGroup'

import { getButtonText } from '@/tool/buttonText'
import './index.scss'

const ActionButton = ({
  app,
  isInstalled,
  handleOnClick,
  className,
  type = 'combo',
  size = 'default',
  mode,
  showIcon = false,
  contentFilter = null,
  tabIndex = 0,
}) => {
  const intl = useIntl()
  const oneButtonConfig = useSelector((state) => state.user.clientUserConfig.one_button_config)
  const localPerference = useSelector((state) => state.app.localPerference[app?.package_name])
  const countryCode = useSelector((state) => state.system.countryCode)
  const buttons = []
  if (!app) {
    return buttons
  }
  const isComboButton = type === 'combo'
  const isGoogleIcon = app.package_name === 'com.android.vending'
  let tooltipId = ''
  // 已安装
  if (isInstalled) {
    // 非详情页且非google play
    if (isComboButton && !isGoogleIcon) {
      tooltipId = 'playOnAppPlayer'
    }
  } else {
    // 未安装情况下判断是否来至于apk
    if (app.isInstallCDN) {
      tooltipId = 'googleInstallAppPlayerTip'
    } else {
      tooltipId = 'googleInstallTip'
    }
  }
  const buttonTextLocation = !isComboButton ? 'appDetailPage' : 'common'
  // one button config(3:表示用户选择，双按钮形式)
  if (oneButtonConfig === 3 && (!isComboButton || countryCode === 'JP')) {
    if (app.showCloudBtn) {
      const { actionText, actionCode, redirectCode, icon } = getButtonText(intl, {
        app,
        platforms: 'cloud',
        location: buttonTextLocation,
        oneButtonConfig,
      })
      buttons.push(
        <CloudButton
          key="cloudButton"
          icon={showIcon && icon}
          tabIndex={tabIndex}
          onClick={(evt) =>
            handleOnClick({ app, evt, gameType: 'cloud', actionCode, redirectCode })
          }
        >
          {actionText}
        </CloudButton>
      )
      if (app.showPlayBtn) {
        const { actionText, actionCode, redirectCode, buttonType, icon } = getButtonText(intl, {
          isInstalled,
          app,
          platforms: 'local',
          location: buttonTextLocation,
          oneButtonConfig,
        })
        buttons.push(
          <RcTooltip
            key="playButtonTooltipWithCloud"
            trigger="hover"
            disabled={!tooltipId}
            overlay={
              <span>
                <FormattedMessage id={tooltipId}></FormattedMessage>
              </span>
            }
          >
            <Button
              key="playButton"
              type={buttonType}
              icon={showIcon && icon}
              tabIndex={tabIndex}
              onClick={(evt) =>
                handleOnClick({ app, evt, gameType: 'simulator', actionCode, redirectCode })
              }
            >
              {isComboButton ? (
                <SvgIcon size={1} icon={'play'} className={['c-white']} />
              ) : (
                actionText
              )}
            </Button>
          </RcTooltip>
        )
      }
    } else if (app.showPlayBtn) {
      const { actionText, actionCode, redirectCode, buttonType, icon } = getButtonText(intl, {
        isInstalled,
        app,
        platforms: 'local',
        location: buttonTextLocation,
        oneButtonConfig,
      })
      buttons.push(
        <RcTooltip
          key="playButtonTooltipWithoutCloud"
          trigger="hover"
          disabled={!tooltipId}
          overlay={
            <span>
              <FormattedMessage id={tooltipId}></FormattedMessage>
            </span>
          }
        >
          <Button
            key="playButton"
            type={buttonType}
            icon={showIcon && icon}
            tabIndex={tabIndex}
            onClick={(evt) =>
              handleOnClick({ app, evt, gameType: 'simulator', actionCode, redirectCode })
            }
          >
            {actionText}
          </Button>
        </RcTooltip>
      )
    }
  } else {
    const { actionText, actionCode, redirectCode, buttonType, icon } = getButtonText(intl, {
      isInstalled,
      app,
      platforms: localPerference,
      location: buttonTextLocation,
      oneButtonConfig: 0,
    })
    // 非详情页或则无tooltipId无tip
    let isToolTipDisabled = isComboButton || !tooltipId
    // 处于详情页且actionCode===playOnCloud时tooltipId为noInstalltionRequired
    if (actionCode === 'playOnCloud' && !isComboButton) {
      isToolTipDisabled = true
    }
    if (app.showPlayBtn || app.showCloudBtn) {
      buttons.push(
        <RcTooltip
          key="mergeButtonTooltip"
          trigger="hover"
          disabled={isToolTipDisabled}
          overlay={
            <span>
              <FormattedMessage id={tooltipId}></FormattedMessage>
            </span>
          }
        >
          {actionCode === 'playOnCloud' && (!isComboButton || countryCode === 'JP') ? (
            <CloudButton
              key="cloudButton"
              icon={showIcon && icon}
              tabIndex={tabIndex}
              onClick={(evt) =>
                handleOnClick({ app, evt, gameType: 'simulator', actionCode, redirectCode })
              }
            >
              {actionText}
            </CloudButton>
          ) : (
            <Button
              key="playButton"
              type={buttonType}
              icon={showIcon && icon}
              tabIndex={tabIndex}
              onClick={(evt) =>
                handleOnClick({ app, evt, gameType: 'simulator', actionCode, redirectCode })
              }
            >
              {actionText}
            </Button>
          )}
        </RcTooltip>
      )
    }
  }
  return (
    <ButtonGroup
      size={size}
      mode={mode}
      className={classNames(['action-button-container', `action-button-${type}`, className])}
    >
      {contentFilter ? contentFilter(buttons) : buttons}
    </ButtonGroup>
  )
}

export default React.memo(ActionButton)
