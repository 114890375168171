import React from 'react'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import { useSelector } from 'react-redux'
const Index = ({ isSuccess, localSvg }) => {
  const isMsi = useSelector((state) => state.system.isMsi)
  return <CommonHelpTip
    textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
    imgSrc={isSuccess ? `noResults${isMsi ? 'Msi' : ''}` : `noNetwork${isMsi ? 'Msi' : ''}`}
    localSvg={localSvg}
  />
}

export default Index
