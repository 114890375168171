/**
 * Show all game list module(Support keyboard event)
 */

import React, { useRef } from 'react'
import GridLayout from '@/components/layout/gridLayout/Index'
import useKeyboardFocus from '@/hook/useKeyboardFocus'
const KeyBoardGridLayout = ({
  keyboardKey = 'common',
  children,
  ...gridLayoutProps
}) => {
  const contentRef = useRef(null)

  const focusIndex = useKeyboardFocus(contentRef, { keyboardKey, columns: gridLayoutProps.columns, count: gridLayoutProps.list.length })
  return (
    <GridLayout
      {...gridLayoutProps}
      ref={contentRef}
    >
      {(item, index) => {
        const tabIndex = focusIndex === index ? 0 : -1
        return children({ tabIndex, gridItem: item, index, tabId: `${keyboardKey}-${index}` })
      }}
    </GridLayout>
  )
}

export default KeyBoardGridLayout
