import React, { useRef, useEffect } from 'react'
// import { useSelector } from 'react-redux'
import { useDebounce, useSize } from 'ahooks'
import classNames from 'classnames'
import getScrollParent from '@/tool/getScrollParent'
import styles from './commonBannerWrap.module.scss'
const CommonBannerWrap = ({ children, className, banner }) => {
  const containerRef = useRef()
  // const fullscreenState = useSelector((state) => state.system.fullscreenState)
  const WidthHeightRatio = window.innerWidth / window.innerHeight
  // const showFullscreen = fullscreenState && WidthHeightRatio <= 2.6504065040650406

  const viewportSize = useSize(containerRef)
  const debounceSize = useDebounce(viewportSize, { wait: 200 })

  // 更新背景图大小
  useEffect(() => {
    if (debounceSize.width) {
      try {
        const dom = containerRef.current.querySelector('.carousel-layout')
        const homeContainerWrap = getScrollParent(containerRef.current)
        if (dom && homeContainerWrap) {
          const domRect = dom.getBoundingClientRect()
          qwebApi({
            event: 'onHomeBannerSizeChange',
            data: {
              left: window.getRealMeasurement(domRect.x + 23 + homeContainerWrap.scrollLeft),
              top: window.getRealMeasurement(
                domRect.y + 50 + domRect.height / 3 + homeContainerWrap.scrollTop
              ),
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [debounceSize.width])

  // 切换banner大图
  useEffect(() => {
    if (banner) {
      let top = 0
      const homeContainerWrap = getScrollParent(containerRef.current)
      if (homeContainerWrap) {
        top = window.getRealMeasurement(homeContainerWrap.scrollTop)
      }
      qwebApi({
        event: 'changeBannerBackgroundImage',
        data: {
          url: banner?.bg_image,
          // video: banner?.bg_video,
          playCount: 1,
          sleep: 500,
          tileRatio: 0.6,
          type: 'position',
          top,
          bg_mask: banner?.isGoRogue ? 'none' : 'default'
        },
      })
    }
  }, [banner])

  return <div ref={containerRef} className={classNames([
    styles.container,
    className,
  ])}
    style={{
      minHeight: `${window.getZoomHeight(WidthHeightRatio < 2.1 ? 0.8 : 1)}px`
    }}
  >
    {/* <div className={styles.emptyBlock}></div> */}
    {children}
  </div>
}

export default CommonBannerWrap
