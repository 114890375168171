import fetch from '../fetch'

/**
 * Fetch top app game
 * @returns Promise
 */
export const getTopGames = () => {
  const url = `${window.__HOST_CONFIG__.xHost}/get_top_game_list`
  return fetch({
    method: 'get',
    url: url,
    noValidate: true,
  })
}

/**
 * Fetch gpt recommended apps
 * @returns Promise
 */
export const getGptRecommendedGames = (package_name) => {
  const url = `${window.__HOST_CONFIG__.xHost}/get_recommend_game_list?package_name=${package_name}`
  return fetch({
    method: 'get',
    url: url,
    noValidate: true,
  })
}
