/**
 * Game list By genre and source
 *
 * Search params:type,from,title,apk,titleId
 */
import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import GamePageHeader from '@/components/common/Heading/GamePageHeader'
import GameGridLayout from '@/components/layout/gameGridLayout/Index'
import { useGridBaseCount } from '@/hook/useViewport'
import {
  STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_HOME,
  STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_GAMECENTERS,
  STAT_APPS_PAGE_TYPEFOR_JUNGAMES_FROM_HOME,
  STAT_APPS_PAGE_TYPEFOR_MUSTPLAYGAMES_FROM_GAMECENTER,
  STAT_APPS_PAGE_TYPEFOR_NEWANDTRENDING_FROM_GAMECENTER,
  STAT_APPS_PAGE_TYPEFOR_TRENDINGGAMES_FROM_HOME,
  STAT_APPS_PAGE_TYPEFOR_POPULARGAMES_FROM_HOME,
  STAT_APPS_PAGE_MORE_STRATEGY_GAMES_FROM_APPDETAIL,
  STAT_APPS_PAGE_SIMILAR_GAMES_FROM_APPDETAIL,
} from '@/tool/constant'

// 根据不同来源-游戏列表
// const typeNameMap = {
//   cloudGames: 'cloudGame',
//   recommendedApps: 'recommendedGames',
//   topApps: 'topGames',
//   hotApps: 'popularGames',
// }

// 根据不同来源-打点类型
const dotTypeMap = {
  // Home
  cloudGames_home: STAT_APPS_PAGE_TYPEFOR_JUNGAMES_FROM_HOME,
  topApps_home: STAT_APPS_PAGE_TYPEFOR_TRENDINGGAMES_FROM_HOME,
  hotApps_home: STAT_APPS_PAGE_TYPEFOR_POPULARGAMES_FROM_HOME,
  recommendedApps_home: STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_HOME,
  // Game Center
  recommendedApps_gameCenter: STAT_APPS_PAGE_TYPEFOR_RECOMMENDEDGAMES_FROM_GAMECENTERS,
  topApps_gameCenter: STAT_APPS_PAGE_TYPEFOR_MUSTPLAYGAMES_FROM_GAMECENTER,
  hotApps_gameCenter: STAT_APPS_PAGE_TYPEFOR_NEWANDTRENDING_FROM_GAMECENTER,
  // App Detail
  moreStrategyGames_appDetail: STAT_APPS_PAGE_MORE_STRATEGY_GAMES_FROM_APPDETAIL,
  similarGames_appDetail: STAT_APPS_PAGE_SIMILAR_GAMES_FROM_APPDETAIL,
}

// app detail apps map<key,field>
const appDetailAppsMap = {
  moreStrategyGames: 'similar_category_games',
  similarGames: 'similar_apps',
}

const AllApps = () => {
  const location = useLocation()
  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])
  const gameType = urlParams.get('type')
  const from = urlParams.get('from')
  const titleId = urlParams.get('titleId')
  const title = urlParams.get('title')
  const pck = urlParams.get('apk')

  const gridCount = useGridBaseCount()

  // Game list selector function
  const selectorFn = useCallback(
    (state) => {
      switch (from) {
        case 'appDetail':
          const detail = state.appdetail.map[pck] || {}
          return detail[appDetailAppsMap[gameType]] || []
        default:
          return state.app[gameType] || state.cloud[gameType] || []
      }
    },
    [pck, from, gameType]
  )
  // Get games by game type (cloud games, android games...)
  const allTypeGames = useSelector(selectorFn)

  // Header title id
  // const titleId = headerTitle || typeNameMap[gameType]

  // Stat location
  const bannerLocation = dotTypeMap[`${gameType}_${from}`]

  return (
    <GameGridLayout
      overscrollBehavior='none'
      gameItemProps={{
        imgPlaceholderClass: 'ratio-1-1',
        showPlayBtn: true,
        showCloudBtn: true,
        showInstalledState: true,
        showCsIcon: true,
        bannerLocation,
      }}
      list={allTypeGames}
      contentInnerClass={['breakScreen', 'margin-bottom-30']}
      columns={gridCount}
      headerRender={() => {
        return (
          <GamePageHeader
            titleId={titleId}
            title={title}
            count={allTypeGames.length}
            className="breakScreen"
          />
        )
      }}
    />
  )
}

export default React.memo(AllApps)
