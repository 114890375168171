import React, { useRef, useMemo, useImperativeHandle } from 'react'
import classNames from 'classnames'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import FormattedText from '@/components/common/FormattedText/Index'

import useScrollBarActive from '@/hook/useScrollBarActive'

import './index.scss'
const PageLayout = (
  {
    onScroll,
    children,
    onBack = () => { },
    titleId = 'goBack',
    className,
    renderHeader = null,
  },
  ref
) => {
  const contentRef = useRef(null)
  useScrollBarActive(contentRef, onScroll)

  useImperativeHandle(ref, () => {
    return {
      getScrollTop: () => contentRef.current?.scrollTop || 0,
      getContent: () => contentRef.current
    }
  })

  return (
    <div className={classNames(['pageLayout', className])}>
      <BaseHeader
        className="pageLayoutHeader"
        type="page"
      >
        {renderHeader || <>
          <button onClick={onBack} className="pageLayoutHeaderTitle">
            <SvgIcon className="headerBackIcon" size={1} icon="go-back" />
            <FormattedText id={titleId} />
          </button>
        </>}
      </BaseHeader>
      <main className="pageLayoutContent" ref={contentRef}>
        {children}
      </main>
    </div>
  )
}

export default React.forwardRef(PageLayout)
