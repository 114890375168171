
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import Button from '@/components/common/Button/Button'
import GameListLayout from '@/components/layout/gameListLayout/Index'
import GamePageHeader from '@/components/common/Heading/GamePageHeader'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import { getGptRecommendedGames } from '@/apis/gpt'
import SkeletonAppList from '@/skeletons/AppList'
import { useGridBaseCount } from '@/hook/useViewport'
import { gptTopGameTransformer } from '@/transformers'
const GptApps = () => {
  const gridCount = useGridBaseCount()
  const [searchParams] = useSearchParams()

  const [apps, setApps] = useState([])
  // Fetch game list
  const { refetch, isSuccess, isFetching, isFetched } = useQuery(
    ['getGptRecommendedGames',],
    () => getGptRecommendedGames(searchParams.get('package_name')),
    {
      onSuccess: (data) => {
        if (data.success) {
          setApps(gptTopGameTransformer(data.data, false))
        }
      },
    }
  )

  const titleJSX = (props) => {
    return <GamePageHeader
      title={'Chat GPT recommended games'}
      count={apps.length}
      {...props}
    />
  }

  if (isFetching) {
    return (
      <SkeletonAppList
        className={['breakScreen', 'margin-bottom-30']}
        imgPlaceholderClass="ratio-1-1"
        column={gridCount}
        headerRender={titleJSX}
        row={2}
        enable={isFetching}
      />
    )
  }

  return (
    <GameListLayout
      overscrollBehavior='none'
      list={apps}
      contentInnerClass={['breakScreen', 'margin-bottom-30']}
      columns={1}
      headerRender={titleJSX({ className: 'breakScreen' })}
      prefix={(list) => {
        if (list.length === 0 && isFetched) {
          return (
            <CommonHelpTip
              textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
              imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
              localSvg
              extra={() => (
                <Button type="primary" onClick={refetch}>
                  <FormattedMessage id="refresh" />
                </Button>
              )}
            />
          )
        }
      }}
    />
  )
}

export default GptApps
