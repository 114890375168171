import React from 'react'
import { useSelector } from 'react-redux'
import Button from '@/components/common/Button/Button'
import './index.scss'
const ViewAllButton = (props) => {
  const isMsi = useSelector((state) => state.system.isMsi)
  return <Button
    type={isMsi ? 'borderGradient' : 'primaryChange'}
    mode={isMsi ? 'stroke' : 'normal'}
    {...props}
  />
}

export default ViewAllButton
