import { createBrowserRouter, Navigate } from 'react-router-dom'

// import Home from '@/components/Home/Home'
import CloudGame from '@/pages/cloudGame/Index'
import CloudGameList from '@/pages/cloudGame/List'
// import NotFound from '@/pages/notFound/Index'
import Search from '@/pages/search/Index'
import GoogleSearch from '@/pages/googleSearch/Index'
import Apps from '@/pages/apps/Index'
import ByTopicApps from '@/pages/apps/ByTopicApps'
import GptGame from '@/pages/gptGame/Index'
import GptRecommendedGame from '@/pages/gptGame/RecommendedApps'
import Collections from '@/pages/collections/Index'
import Home from '@/pages/home/Index'
import AppDetail from '@/pages/appDetail/Index'
import React from 'react'
import CommonAnimation from '@/components/common/Animation/CommonAnimation'

const routes = [
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/app/:packageName',
    element: <AppDetail />,
  },
  {
    path: '/cloudGame',
    element: <CloudGame />,
  },
  {
    path: '/cloudGameByCategory',
    element: <CloudGameList />
  },
  {
    path: '/search',
    element: <Search />,
  },
  {
    path: '/googleSearch',
    element: <GoogleSearch />,
  },
  {
    path: '/apps',
    element: <Apps />,
  },
  {
    path: '/byTopicApps',
    element: <ByTopicApps />,
  },
  {
    path: '/collections',
    element: <Collections />,
  },
  {
    path: '/gptGame',
    element: <GptGame />
  },
  {
    path: '/gptRecommended',
    element: <GptRecommendedGame />
  },
  {
    path: '',
    element: <Home />
  }
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <CommonAnimation routes={routes} />,
    errorElement: <Navigate to='/home' replace />,
    children: routes.map((route) => ({
      index: route.path === '/home',
      path: route.path,
      element: route.element,
    })),
  },
])

export default router
