import React, { useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PageLayout from '@/components/layout/pageLayout/Index'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import RefreshIcon from '@/components/Carousel/components/RefreshIcon'
import FormattedText from '@/components/common/FormattedText/Index'
import { qwebApi } from '@/apis/qwebApi'
import { shuffleRecommendedApps } from '@/reducers/app/actions'
import { arrayShuffle } from '@/tool'
import { STAT_GOOGLE_SEARCH_PAGE_RECOMMENDED } from '@/tool/constant'

import RecentSearch from './components/recentSearch/Index'
import Search from './components/search/Index'
import style from './index.module.scss'
import gpbgImage from '@/images/search/gp_bg.png'
import { useGridBaseCount } from '@/hook/useViewport'
const GoogleSearch = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  // Recommended Games
  const recommendedApps = useSelector((state) => state.app.recommendedApps)

  const state = location.state

  const gridCount = useGridBaseCount()

  useEffect(() => {
    qwebApi({
      event: 'changeBannerBackgroundImage',
      data: {
        url: `${window.location.origin}${process.env.PUBLIC_URL}${gpbgImage}`, //state?.full_hd_image,
      },
    })
  }, [state])

  // Switch client search-bar
  useEffect(() => {
    qwebApi({ event: 'setSearchVisibility', data: { action: 'hide' } })
    return () => {
      qwebApi({ event: 'setSearchVisibility', data: { action: 'show' } })
    }
  }, [])

  // Refresh recommended apps
  const onRefresh = () => {
    dispatch(shuffleRecommendedApps(arrayShuffle([...recommendedApps])))
  }

  // Custom title render jsx
  const renderHeader = () => {
    return <BaseHeader>
      <span id="recommendedGames"><FormattedText id='recommendedGames' /></span>
      <RefreshIcon onRefresh={onRefresh} />
    </BaseHeader>
  }
  return (
    <PageLayout className={[style.container]} onBack={() => navigate(-1)}>
      <div className={style.searchContainer}>
        <Search />
      </div>
      <div className={style.content}>
        <RecentSearch className={[style.recentSearches, 'breakScreenRight']} />
        {/* Recommended For You */}
        {recommendedApps.length ? (
          <ErrorFallback>
            <GameCarouselSection
              // slideConfig={{ spaceBetween: 43 }}
              className={[style.recommendedForYou, 'breakScreenRight']}
              carouselKeyId="recommendedGames"
              titleId="recommendedGames"
              bannerLocation={STAT_GOOGLE_SEARCH_PAGE_RECOMMENDED}
              list={recommendedApps}
              renderHeader={renderHeader}
              // onRefresh={onRefresh}
              pagination={false}
              showPlayBtn
              showCloudBtn
              imgPlaceholderClass="ratio-1-1"
              showInstalledState
              slidesPerView={gridCount}
              isGroup={false}
            />
          </ErrorFallback>
        ) : null}
      </div>
    </PageLayout>
  )
}

export default React.memo(GoogleSearch)
