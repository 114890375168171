import React, { useRef } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import useKeyboardFocus from '@/hook/useKeyboardFocus'
import CategoryItem from './Item'
import styles from './index.module.scss'
import SkeletionsCloudGameCategory from '@/skeletons/cloudGameCategory/Index'
const keyboardKey = 'discoverYourFavoriteGamingGenre'
const Categorys = ({ className }) => {
  const contentRef = useRef(null)
  const categorys = useSelector((state) => state.cloud.categorys)
  const loadingStore = useSelector((state) => state.loading)
  const focusIndex = useKeyboardFocus(contentRef, {
    keyboardKey,
    count: categorys.length,
    direction: 'horizontal',
  })
  return (
    <section className={classNames([styles.container, className])}>
      <header className={styles.header}>
        <FormattedMessage id="discoverYourFavoriteGamingGenre" />
      </header>
      <div className={styles.content} ref={contentRef}>
        {categorys.length > 0 ? (
          categorys.map((category, index) => {
            const tabIndex = focusIndex === index ? 0 : -1
            const tabId = `${keyboardKey}-${index}`
            return (
              <CategoryItem
                key={category.title}
                category={category}
                tabIndex={tabIndex}
                id={tabId}
              />
            )
          })
        ) : (
          <SkeletionsCloudGameCategory count={10} enable={loadingStore.cloudGameLoading} />
        )}
      </div>
    </section>
  )
}

export default Categorys
