

const Hook = (clickHandler) => {
  const onKeyDown = (evt) => {
    if (evt.code === 'Enter' || evt.code === 'Space') {
      clickHandler(evt)
    }
  }
  return {
    onClick: clickHandler,
    onKeyDown
  }
}

export default Hook
