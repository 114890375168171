import React from 'react'
import { useSelector } from 'react-redux'
import _SwiperSwitch from '@/components/common/SwiperSwitch/banner/Index'
import './banner.scss'
const SwiperSwitch = ({ className, ...props }) => {
  const isMsi = useSelector((state) => state.system.isMsi)
  return (
    <_SwiperSwitch
      iconPrev={isMsi ? 'prev-circle' : 'prev'}
      iconNext={isMsi ? 'next-circle' : 'next'}
      className={[isMsi ? 'msiSwiperSwitch-container' : 'swiperSwitch-container', className]}
      size={isMsi ? 2.5 : 1}
      {...props}
    />
  )
}

export default SwiperSwitch
