import React from 'react'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import Wrap from '../Wrap'
import './home_banner.scss'

const HomeBanner = ({ className, enable }) => {
  return (
    <Wrap enable={enable}>
      <div className={classNames(['skeleton_msi_homeBanner', className])}>
        <Skeleton height="100%" borderRadius={12} />
      </div>
    </Wrap>
  )
}

export default HomeBanner
