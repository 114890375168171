import React from 'react'
import { useSelector } from 'react-redux'
import Button from '@/components/common/Button/Button'
import './index.scss'
const CloudButton = (props) => {
  const isMsi = useSelector((state) => state.system.isMsi)
  return <Button
    type={isMsi ? 'whiteChange' : 'pinkChange'}
    mode="stroke"
    className="adapter-cloud-button"
    {...props}
  />
}

export default CloudButton
