const themeMap = {
  nxt: 'lighter',
  msi5: 'wine-red-dark'
}
const themeMapProxy = new Proxy(themeMap, {
  get(obj, key) {
    return obj[key] || 'lighter'
  },
})

export const getThemeByOem = (oem) => {
  return themeMapProxy[oem]
}

export const themeIsValid = (theme) => {
  const themes = Object.values(themeMap)
  return themes.includes(theme)
}
