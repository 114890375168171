import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import 'rc-rate/assets/index.css'
import GameCarouselTwo from '@/components/CarouselSection/GameCarouselSectionForHomeBanner'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import CommonBannerWrap from "@/components/banner/CommanBannerWrap"
import BannerOne from '@/components/banner/bannerOne/Index'
import { onGameClickAction } from '@/actions/commonActions'
import SkeletonGameList from '@/skeletons/GameList'
import SkeletonHomeBanner from '@/skeletons/HomeBanner'
import { STAT_HOME_PAGE_BANNER } from '@/tool/constant'
import styles from './banner.module.scss'

const HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY = 'homeBannerActiveIndex'

const HomeBanner = ({ className, gridCount = 4 }) => {
  const dispatch = useDispatch()
  const [bannerGame, setBannerGame] = useState(null)
  const swiperInitState = useRef(false)
  const [activeIndex, setActiveIndex] = useState(
    () => +window.sessionStorage.getItem(HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY) || 0
  )

  const rotationBanners = useSelector((state) => state.app.rotationBanners)

  const loadingStore = useSelector((state) => state.loading)


  // 打开游戏
  const handleOnClick = useCallback(
    ({ evt, gameType, isInstalled, app, actionCode, redirectCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        dispatch,
        gameType,
        bannerLocation: STAT_HOME_PAGE_BANNER,
        actionCode,
        redirectCode,
      })
    },
    [dispatch]
  )

  // 鼠标移入事件
  const onHoverEnter = useCallback(
    (item, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )
  // 页面初始化
  useEffect(() => {
    if (rotationBanners.length) {
      setBannerGame(rotationBanners[activeIndex])
    }
  }, [rotationBanners, activeIndex])

  useEffect(() => {
    window.sessionStorage.setItem(HOME_BANNER_ACTIVE_INDEX_STORAGE_KEY, activeIndex)
  }, [activeIndex])

  return (
    <CommonBannerWrap className={classNames([styles.container, className])} banner={bannerGame}>
      <div className={styles.bannerInfo}>
        {bannerGame ? (
          <BannerOne banner={bannerGame} handleOnClick={handleOnClick} />
        ) : (
          <SkeletonHomeBanner enable={loadingStore.gameCenterRotationLoading} />
        )}
      </div>
      {rotationBanners.length ? (
        <ErrorFallback>
          <GameCarouselTwo
            className={styles.bannerList}
            carouselKeyId="homeBannerGames"
            list={rotationBanners}
            imgPlaceholderClass="ratio-1-1"
            bannerLocation={STAT_HOME_PAGE_BANNER}
            showCloudBtn
            showPlayBtn
            showInstalledState
            showDetailIcon={false}
            slidesPerView={gridCount + 1}
            onHoverEnter={onHoverEnter}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            slideConfig={{
              initialSlide: activeIndex,
              onActiveIndexChange(swiper) {
                console.log(swiper.activeIndex,swiperInitState.current)
                if (swiperInitState.current) {
                  setActiveIndex(swiper.activeIndex)
                }
              },
              onInit(swiper) {
                setTimeout(() => {
                  swiper.slideTo(activeIndex, 0)
                  swiperInitState.current = true
                }, 100)
              }
            }}
          />
        </ErrorFallback>
      ) : (
        <SkeletonGameList
          className={styles.skeletonContainer}
          column={gridCount + 1}
          showHeader={false}
          gameItemProps={{ showSubTitle: false }}
          enable={loadingStore.gameCenterRotationLoading}
        />
      )}
    </CommonBannerWrap>
  )
}

export default HomeBanner
