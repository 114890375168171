import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useSelector } from 'react-redux'
import { useSize } from 'ahooks'
import { STAT_HOME_PAGE_BANNER } from '@/tool/constant'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import SkeletonMsiHomeBanner from '@/skeletons/msi/HomeBanner'
import Slider from './Slider'
import CustomCarousel from './carousel/Index'

import './index.scss'

const MsiHomeBanner = ({ className }) => {
  const rotationBanners = useSelector((state) => state.app.rotationBanners)
  const loadingStore = useSelector((state) => state.loading)
  const swiperInstanceRef = useRef(null)
  const containerRef = useRef(null)

  const { width } = useSize(containerRef)

  const onKeyDown = (evt) => {
    if (evt.code === 'ArrowRight') {
      swiperInstanceRef.current.next()
    } else if (evt.code === 'ArrowLeft') {
      swiperInstanceRef.current.prev()
    }
    if (evt.code === 'ArrowRight' || evt.code === 'ArrowLeft') {
      setTimeout(() => {
        containerRef.current?.querySelector('.isCenter').focus()
      }, 100)
    }
  }

  useEffect(() => {
    let timer = null
    async function getCenterItemHeight() {
      if (containerRef.current && containerRef.current.style) {
        let centerItem = containerRef.current.querySelector('.isCenter')
        while (!centerItem) {
          await new Promise((resolve) => {
            timer = setTimeout(resolve, 300)
          })
          centerItem = containerRef.current.querySelector('.isCenter')
        }
        containerRef.current.style.height = `${centerItem.clientHeight + 4}px`
      }
    }
    getCenterItemHeight()
    return () => clearTimeout(timer)
  }, [width])
  if (rotationBanners.length === 0 && !loadingStore.gameCenterRotationLoading) {
    return null
  }
  return (
    <section
      className={classNames(['msiBanner-container', className])}
      onKeyDown={onKeyDown}
      ref={containerRef}
    >
      <ErrorFallback>
        {rotationBanners.length ? (
          <CustomCarousel
            ref={swiperInstanceRef}
            list={rotationBanners}
            renderItem={({ item, isCenter }) => {
              return (
                <Slider
                  app={item}
                  showInstalledState
                  bannerLocation={STAT_HOME_PAGE_BANNER}
                  className={['section-carousel-item', { isCenter }]}
                  tabIndex={isCenter ? 0 : -1}
                />
              )
            }}
          />
        ) : (
          <SkeletonMsiHomeBanner enable={loadingStore.gameCenterRotationLoading} />
        )}
      </ErrorFallback>
    </section>
  )
}

export default MsiHomeBanner
