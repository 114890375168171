import React from 'react'
import { useSelector } from 'react-redux'
import _SwiperSwitch from '@/components/common/SwiperSwitch/common/Index'
import { usePxToPx } from '@/hook/useViewport'
const SwiperSwitch = (props) => {
  const isMsi = useSelector((state) => state.system.isMsi)
  const gapSize = usePxToPx(17)
  return (
    <_SwiperSwitch
      iconPrev={isMsi ? 'prev-circle' : 'prev'}
      iconNext={isMsi ? 'next-circle' : 'next'}
      style={isMsi ? { gap: 0 } : { gap: `${gapSize}px` }}
      size={isMsi ? 3 : 1}
      {...props}
    />
  )
}

export default SwiperSwitch
