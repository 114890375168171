import React, { useRef } from 'react'
import HomeSectionOne from './homeSectionOne/Index'
import HomeSectionTwo from './homeSectionTwo/Index'
import useBackgroundImageScroll from '@/hook/useBackgroundImageScroll'
import useScrollMemory from '@/hook/useScrollMemory'
import useScrollBarActive from '@/hook/useScrollBarActive'
import { useGridBaseCount } from '@/hook/useViewport'


import './index.scss'

const HomeGameCenter = () => {
  const containerRef = useRef(null)
  const gridCount = useGridBaseCount()

  useBackgroundImageScroll(containerRef)
  useScrollMemory(containerRef, { key: 'home-scroll-memory' })
  useScrollBarActive(containerRef)

  return <main className='home-container' ref={containerRef}>
    <HomeSectionOne className="breakScreen" gridCount={gridCount} />
    <HomeSectionTwo className="breakScreen margin-bottom-30" gridCount={gridCount} />
  </main>
}

export default HomeGameCenter
