import React, { useMemo, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { useInViewport } from 'ahooks'
import Rate from 'rc-rate'
import 'rc-rate/assets/index.css'
import AspectImage from '@/components/common/Image/AspectImage'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import style from './banner.module.scss'
const Banner = ({
  className,
  appOverview,
  isInstalled,
  onVisibleChange,
  actionButton = null,
  supports = [],
}) => {
  const containerRef = useRef(null)
  const isVisible = useInViewport(containerRef)
  // const debounceIsVisible = useDebounce(isVisible, { wait: 300 })

  useEffect(() => {
    onVisibleChange(isVisible)
  }, [isVisible, onVisibleChange])

  const supportsJSX = useMemo(() => {
    if (supports.length === 0) {
      return null
    }
    return (
      <div className={style.supportsContainer} key="supportIcons">
        {supports.map((support) => (
          <div className={style.supportIcon} key={support}>
            <SvgIcon size={1} icon={support} />
          </div>
        ))}
      </div>
    )
  }, [supports])

  const describtionJSX = useMemo(() => {
    const jsxs = []
    jsxs.push(
      <div aria-hidden="true" key="app_rate">
        <Rate
          className={style.rate}
          disabled
          defaultValue={0}
          value={parseFloat(appOverview.app_rating) || 0}
          style={{ fontSize: 14 }}
          character={<SvgIcon size={1} icon="star" />}
          allowHalf
          allowClear={false}
        /></div>
    )
    if (appOverview.genre_slug) {
      jsxs.push(
        <span className="uppercase" key="genreSlug">
          {appOverview.genre_slug}
        </span>
      )
    }
    if (appOverview.developer_name) {
      jsxs.push(
        <span className="uppercase" key="developerName">
          {appOverview.developer_name}
        </span>
      )
    }
    if (supportsJSX) {
      jsxs.push(supportsJSX)
    }
    for (let index = jsxs.length - 1; index > 0; index--) {
      jsxs.splice(index, 0, <span key={`split_${index}`} className={style.split}></span>)
    }
    if (jsxs.length) {
      return <div className={style.describtion}>{jsxs}</div>
    } else {
      return null
    }
  }, [appOverview.genre_slug, appOverview.developer_name, appOverview.app_rating, supportsJSX])

  return (
    <section className={classNames([style.container, className])} ref={containerRef}>
      <AspectImage className={style.gameIcon} src={appOverview.icon_url} alt="appPageBannerImage">
        {/* 已安装 */}
        {isInstalled && <SvgIcon size={1} icon="installed" className="installed" />}
      </AspectImage>
      <div className={style.gameDesc}>
        <h1
          className={classNames([
            style.title,
            { [style.doubleLineTitle]: appOverview.app_rating === undefined },
          ])}
        >
          {appOverview.app_name}
        </h1>
        {describtionJSX}
        {actionButton}
      </div>
    </section>
  )
}

export default Banner
