import { cloudGanmeAppsTransformer, ncmAppsTransformer } from '@/transformers'
import * as types from './constant'
import { getCloudGameApps, getNCMAppList } from '@/apis/apps'

// Fetch cloud game apps action
export const fetchCloudGameApps = () => {
  return async (dispatch) => {
    try {
      const _result = await getNCMAppList()
      dispatch({
        type: types.FETCH_CLOUDGAMES_APPS,
        payload: ncmAppsTransformer(_result.apps || []),
      })
    } catch (error) {
      dispatch({
        type: types.FETCH_CLOUDGAMES_APPS,
        payload: [],
      })
    }
  }
}

// Fetch juns game apps action
export const fetchJunsGameApps = ({ locale }) => {
  return async (dispatch, getState) => {
    const localeCode = locale || getState().system.localeCode
    try {
      const _result = await getCloudGameApps({ tags: `jun's-game`, locale: localeCode })
      dispatch({
        type: types.FETCH_JUNS_GAME_APPS,
        payload: cloudGanmeAppsTransformer(_result),
      })
    } catch (error) {
      dispatch({
        type: types.FETCH_JUNS_GAME_APPS,
        payload: [],
      })
    }
  }
}
