import React, { useState, useEffect, useImperativeHandle, useRef, useMemo } from 'react'
import classNames from 'classnames'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import './index.scss'

const CustomCarousel = ({
  list,
  renderItem,
  autoRoll = false,
  direction = 'left',
  interval = 5000,
  className,
  onChange
}, ref) => {
  const [centerIndex, setCenterIndex] = useState(0)
  const timerRef = useRef(null)

  // ceter定位移动
  const _target = (pos) => {
    clearInterval(timerRef.current)
    let _index = pos
    if (pos > list.length - 1) {
      _index = pos - list.length
    } else if (pos < 0) {
      _index = pos + list.length
    }
    setCenterIndex(_index)
    onChange && onChange(_index)
  }
  // center右移
  const _nextPic = () => {
    _target(centerIndex + 1)
  }
  // center左移
  const _prePic = () => {
    _target(centerIndex - 1)
  }

  useEffect(() => {
    if (autoRoll) {
      if (direction === 'right') {
        timerRef.current = setInterval(_prePic, interval)
      } else {
        timerRef.current = setInterval(_nextPic, interval)
      }
    }
    return () => clearInterval(timerRef.current)
  }, [autoRoll, direction, interval])

  const childJsx = useMemo(() => {
    const size = list.length
    return list
      .map((item, index) => {
        const _isLeft = (index + 1) % size === centerIndex
        const _isRight = (index - 1 + size) % size === centerIndex
        const _isCenter = index === centerIndex

        const pos = _isCenter ? 'center' : _isRight ? 'right' : _isLeft ? 'left' : 'wait'
        return <li className={`b-${pos}`} key={index}>
          {renderItem({ item, isCenter: _isCenter, index })}
        </li>
      })
      .filter(Boolean)
  }, [renderItem, centerIndex, list])

  useImperativeHandle(ref, () => {
    return {
      prev: _prePic,
      next: _nextPic,
      slideTo: _target
    }
  })
  return (
    <div className={classNames(['banner', className])}>
      <SvgIcon size={1} icon="msi-narrow-right" className="banner-navigation-left" onClick={_prePic} />
      <ul className="banner-slide">{childJsx}</ul>
      <SvgIcon size={1} icon="msi-narrow-right" className="banner-navigation-right" onClick={_nextPic} />
    </div>
  )
}

export default React.forwardRef(CustomCarousel)
