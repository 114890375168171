import React from 'react'
import { useSelector } from 'react-redux'
import _HomeBanner from '@/pages/home/homeSectionOne/banner/Index'
import MsiHomeBanner from '@/pages/home/homeSectionOne/msiBanner/Index'
const HomeBanner = (props) => {
  const isMsi = useSelector((state) => state.system.isMsi)
  if (isMsi) {
    return <MsiHomeBanner {...props} />
  } else {
    return <_HomeBanner {...props} />
  }
}

export default HomeBanner
