import produce from 'immer'
import * as types from './constant'

import { isQtClient } from '@/tool/qwebchannel/utils'

import { qwebApi } from '@/apis/qwebApi'

// Country storage key
export const COUNTRY_CODE_STORAGE_KEY = 'COUNTRY_CODE_STORAGE_KEY'
// App current location storage key
export const LAST_APP_LOCATION = 'LAST_APP_LOCATION'
// Client mode storage key
export const CLIENT_MODE_KEY = 'CLIENT_MODE_KEY'
// Language storage key
export const LANGUAGE_STORAGE_KEY = 'LANGUAGE_STORAGE_KEY'

const INIT_STATE = {
  showOfflinePage: false,
  fullscreenState: false,
  isOnline: true,
  loader: false,
  countryCode: window.localStorage.getItem(COUNTRY_CODE_STORAGE_KEY) || '',
  // use for fetch cloud game apps
  localeCode: isQtClient ? '' : 'en',
  // use for map client language
  language: window.localStorage.getItem(LANGUAGE_STORAGE_KEY) || 'en-US',
  location: window.location.pathname.slice(1) || 'home',
  clientSystemConfig: {},
  mode: window.localStorage.getItem(CLIENT_MODE_KEY) || 'hybrid',
  oem: 'nxt',
  isMsi: false,

}
export default produce((draft, { type, payload }) => {
  switch (type) {
    case types.SET_SHOW_APP_OFFLINE_PAGE:
      draft.showOfflinePage = payload
      break
    case types.SET_IS_ONLINE:
      draft.isOnline = payload
      break
    case types.SWITCH_LOADER: {
      if (payload) {
        qwebApi({ event: 'openClientLoading' })
      } else {
        qwebApi({ event: 'closeClientLoading' })
      }
      draft.loader = payload
      break
    }
    case types.SET_APP_COUNTRY_CODE: {
      if (payload) {
        window.localStorage.setItem(COUNTRY_CODE_STORAGE_KEY, payload)
        qwebApi({ event: 'syncCountryCode', data: { countryCode: payload } })
        draft.countryCode = payload
      }
      break
    }
    case types.SET_APP_LOCAL_CODE: {
      draft.localeCode = payload
      break
    }
    case types.SET_APP_LANGUAGE: {
      if (payload) {
        window.localStorage.setItem(LANGUAGE_STORAGE_KEY, payload)
        document.documentElement.setAttribute('lang', payload)
        draft.language = payload
      }
      break
    }
    case types.SET_APP_LOCATION_PATH: {
      draft.location = payload
      break
    }
    case types.SET_APP_CLIENT_SYSTEM_CONFIG: {
      draft.clientSystemConfig = payload
      break
    }
    case types.SET_FULLSCREEN_STATE: {
      draft.fullscreenState = payload
      break
    }
    case types.SET_APP_CLIENT_MODE: {
      if (payload) {
        window.localStorage.setItem(CLIENT_MODE_KEY, payload)
      }
      draft.mode = payload
      break
    }
    case types.SET_OEM: {
      const isMsi = payload === 'msi5'
      draft.oem = payload
      draft.isMsi = isMsi
      break
    }
    default: {
    }
  }
}, INIT_STATE)
