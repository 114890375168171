import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Virtual } from 'swiper'
import classNames from 'classnames'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import CommonBannerWrap from "@/components/banner/CommanBannerWrap"
import BannerOne from '@/components/banner/bannerOne/Index'
import { onGameClickAction } from '@/actions/commonActions'
import SkeletonGameList from '@/skeletons/GameList'
import SkeletonHomeBanner from '@/skeletons/HomeBanner'
import GameCarouselTwo from '@/components/CarouselSection/GameCarouselSectionForHomeBanner'
import { STAT_CLOUD_GAME_PAGE_BANNER } from '@/tool/constant'

import styles from './banner.module.scss'

const CLOUD_GAME_BANNER_ACTIVE_INDEX_STORAGE_KEY = 'cloudGameBannerActiveIndex'
const CloudGameBanner = ({ gridCount, className }) => {
  const dispatch = useDispatch()
  const [isInitPage, setIsInitPage] = useState(true)
  const cloudApps = useSelector((state) => state.cloud.cloudGames) || []
  const loadingStore = useSelector((state) => state.loading)
  const [bannerGame, setBannerGame] = useState(null)
  const [activeIndex, setActiveIndex] = useState(
    +window.sessionStorage.getItem(CLOUD_GAME_BANNER_ACTIVE_INDEX_STORAGE_KEY) || 0
  )
  // 页面初始化
  useEffect(() => {
    if (cloudApps.length) {
      setBannerGame(cloudApps[activeIndex])
    }
  }, [cloudApps, activeIndex])

  // 打开游戏
  const handleOnClick =
    ({ evt, gameType, isInstalled, app, actionCode, redirectCode }) => {
      onGameClickAction({
        app,
        isInstalled,
        evt,
        dispatch,
        gameType,
        bannerLocation: STAT_CLOUD_GAME_PAGE_BANNER,
        actionCode,
        redirectCode,
      })
    }
  // 鼠标移入事件
  const onHoverEnter = useCallback(
    (item, index) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )

  useEffect(() => {
    window.sessionStorage.setItem(CLOUD_GAME_BANNER_ACTIVE_INDEX_STORAGE_KEY, activeIndex)
  }, [activeIndex])
  return <CommonBannerWrap className={classNames([styles.container, className])} banner={bannerGame}>
    <div className={styles.bannerInfo} >
      {bannerGame ?
        <BannerOne buttonMode="round" banner={bannerGame} handleOnClick={handleOnClick} /> :
        <SkeletonHomeBanner enable={loadingStore.cloudGameLoading} className="bannerOne-skeleton" />}
    </div>
    {cloudApps.length ? (
      <ErrorFallback>
        <GameCarouselTwo
          className={styles.bannerList}
          carouselKeyId="instantPlayBannerGames"
          list={cloudApps}
          imgPlaceholderClass="ratio-1-1"
          bannerLocation={STAT_CLOUD_GAME_PAGE_BANNER}
          showCloudBtn
          showPlayBtn
          showInstalledState
          showDetailIcon={false}
          slidesPerView={gridCount + 1}
          onHoverEnter={onHoverEnter}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          slideConfig={{
            modules: [Virtual],
            virtual: true,
            onActiveIndexChange: (swiper) => {
              if (!isInitPage) {
                setActiveIndex(swiper.activeIndex)
              }
            },
            onInit: (swiper) => {
              setTimeout(() => {
                swiper.slideTo(activeIndex, 0)
                setIsInitPage(false)
              }, 100)
            },
          }}
        />
      </ErrorFallback>
    ) : (
      <SkeletonGameList
        className={styles.skeletonContainer}
        column={gridCount + 1}
        showHeader={false}
        gameItemProps={{ showSubTitle: false }}
        enable={loadingStore.cloudGameLoading}
      />
    )}
  </CommonBannerWrap>
}

export default CloudGameBanner
