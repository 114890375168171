import React, { useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'
import BaseHeader from '@/components/common/Heading/BaseHeader'
import FormattedText from '@/components/common/FormattedText/Index'
import style from './describtion.module.scss'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
const Describtion = ({ className, describtion }) => {
  const contentRef = useRef(null)
  const contentInnerRef = useRef(null)
  const [isExpand, setIsExpand] = useState(false)
  const [showToggleBtn, setShowToggleBtn] = useState(true)

  useLayoutEffect(() => {
    if (contentInnerRef.current) {
      const contentHeight = contentInnerRef.current.clientHeight
      if (isExpand) {
        contentRef.current.style.height = `${contentHeight}px`
      } else {
        contentRef.current.style.height = `${Math.min(contentHeight, 120)}px`
      }
      if (contentHeight <= 120) {
        setShowToggleBtn(false)
      } else {
        setShowToggleBtn(true)
      }
    }
  }, [isExpand])
  return (
    <section className={classNames([style.container, className])} aria-labelledby="overview overview_sub">
      <BaseHeader className={style.header}>
        <span id='overview'>
          <FormattedText id='overview' />
        </span>
      </BaseHeader>
      <div className={style.content}>
        <h3 className={style.subHeader} id="overview_sub">{describtion.game_description_heading}</h3>
        <div ref={contentRef} className={classNames([style.description, { [style.expand]: isExpand }])} >
          <div aria-hidden="true"
            ref={contentInnerRef}
            className={classNames(['opaqueWhiteText'])}
            dangerouslySetInnerHTML={{
              __html: (
                describtion.game_description ||
                describtion.google_play_description ||
                ''
              ).replace(/<a.*?href=['"](.*?)['"].*?>(.*?)<\/a>/g, '$2'),
            }}
          />
        </div>
        {showToggleBtn && (
          <button className={style.showMore} onClick={() => setIsExpand(!isExpand)}>
            <FormattedMessage id={isExpand ? 'showLess' : 'showMore'} />
            <SvgIcon
              className={classNames([style.toggleIcon, { [style.rotationIcon]: isExpand }])}
              size={1}
              icon="arrow-down-circle"
            />
          </button>
        )}
      </div>
    </section>
  )
}

export default Describtion
