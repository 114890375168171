import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import GameCarouselSection from '@/components/CarouselSection/GameCarouselSection'
import CloudGameCarousel from './components/gameCarousel/Banner'
import SkeletonGameList from '@/skeletons/GameList'
import useBackgroundImageScroll from '@/hook/useBackgroundImageScroll'
import useScrollMemory from '@/hook/useScrollMemory'
// import './index.scss'
import { STAT_CLOUD_GAME_PAGE_LIST } from '@/tool/constant'
import { useGridBaseCount } from '@/hook/useViewport'
import Categorys from './components/categorys/Index'
import EmptyContainer from './components/empty/Index'

import './index.scss'
// 记录页面的状态
const CloudGame = () => {
  const containerRef = useRef()
  const intl = useIntl()
  const loadingStore = useSelector((state) => state.loading)
  // 云游戏
  const categoryMap = useSelector((state) => state.cloud.categoryMap)
  const countryCode = useSelector((state) => state.system.countryCode)
  const gridCount = useGridBaseCount()

  const categorys = Object.keys(categoryMap)

  useBackgroundImageScroll(containerRef)
  useScrollMemory(containerRef, { key: 'cloudGame-scroll-memory' })

  if (loadingStore.cloudGameLoading === false && categorys.length === 0) {
    return <div className='cloudGame-empty'><EmptyContainer /></div>
  }


  return <div className='cloudGame-container' ref={containerRef}>
    <CloudGameCarousel gridCount={gridCount} className="breakScreen margin-bottom-50" />
    {/* category */}
    <Categorys className='breakScreen' />
    {/* Category Games in your region */}
    {
      categorys.length > 0 ? categorys.map(category => {
        const list = categoryMap[category]
        const title = intl.formatMessage({ id: 'subHeadingCategory' }, { category })
        return <GameCarouselSection
          key={category}
          className="breakScreen"
          carouselKeyId={category.replace(/\s/g, '_')}
          titleClass="spaceBetween"
          title={title}
          list={list}
          linkTo={{
            pathname: '/cloudGameByCategory',
            search: `category=${category}&title=${title}&from=cloudGame`,
          }}
          showCloudBtn
          bannerLocation={STAT_CLOUD_GAME_PAGE_LIST}
          imgPlaceholderClass="ratio-3-4"
          imageUrlGet={(game) => (countryCode === 'JP' ? game.icon_url : game.banner_url_v)}
          slidesPerView={gridCount - 1}
          isGroup={false}
        />
      }) : <SkeletonGameList
        imgPlaceholderClass="ratio-3-4"
        titleId="androidGamesLike"
        column={gridCount - 1}
        enable={loadingStore.cloudGameLoading}
      />
    }
    <div className='margin-bottom-30'></div>
  </div>
}

export default CloudGame
