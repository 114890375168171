import React from 'react'
import { useNavigate } from 'react-router'
import { useIntl } from 'react-intl'
import AspectImage from '@/components/common/Image/AspectImage'
import useNarrorClick from '@/hook/useNarrorClick'
import styles from './index.module.scss'
const CategoryItem = ({ category, ...props }) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const title = intl.formatMessage({ id: 'subHeadingCategory' }, { category: category.title })
  const onItemClick = () => {
    navigate({
      pathname: '/cloudGameByCategory',
      search: `category=${category.title}&title=${title}&from=cloudGame`,
    })
  }
  const eventProps = useNarrorClick(onItemClick)
  return (
    <div className={styles.categoryItem} {...eventProps} {...props}>
      <div className={styles.logo}>
        <AspectImage aspectClass="ratio-1-1" src={category.icon} />
      </div>
      <span className={styles.title}>{title}</span>
    </div>
  )
}

export default CategoryItem
