import store from '@/store'
import { GOOGLE_PLAY_PCK } from '@/tool/constant'
/**
 *  Get the app {actionText,actionCode,gameType,redirectCode:(AppGoogleSearch,AppDetail,AppLauncher)} according to the configuration
 * @param {object} intl Intl
 * @param {object} param1 {isInstalled:is it installed,app:app info,platforms:user-specified priority startiup way,location:execution location,oneButtonConfig:client one-button config}
 * @returns object
 */
export const getButtonText = (
  intl,
  {
    // whether id installed,default false
    isInstalled = false,
    app,
    platforms,
    location = 'common',
    // one-button config (3: user selector mode; 0: system auto selector)
    oneButtonConfig = 3,
  }
) => {
  const {
    isInstallCDN = false,
    isOpenInBrowser = false,
    button_text,
    package_name,
    game_play_preference = ['local'],
    buttonType = '',
  } = app
  // whether is system auto selector
  const isAutoConfig = oneButtonConfig === 0
  // whether is google-store app
  const isGooglePlay = package_name === GOOGLE_PLAY_PCK
  // play cloud game in browser directly
  if (buttonType === 'goCloud') {
    const id = 'playInBrowser'
    return {
      actionText:
        intl &&
        intl.formatMessage({
          id,
        }),
      actionCode: 'playOnCloud',
      gameType: 'cloud',
      redirectCode: 'AppLauncher',
      buttonType: 'pinkChange',
      icon: 'nowgg',
    }
  }

  if (isGooglePlay) {
    return {
      actionText:
        intl &&
        intl.formatMessage({
          id: process.env['REACT_APP_SHOW_GP_STORE'] ? 'visitStore' : 'openGooglePlay',
        }),
      actionCode: 'openGooglePlay',
      gameType: 'simulator',
      redirectCode: process.env['REACT_APP_SHOW_GP_STORE'] ? 'AppGoogleSearch' : 'AppLauncher',
      buttonType: 'primaryChange',
      icon: 'bluestacks',
    }
  }
  // gpt list
  if (app.isGPTGame && location === 'common') {
    return {
      actionText: 'View remcommented Games',
      actionCode: 'openGptRecommended',
      gameType: 'simulator',
      redirectCode: 'GptRecommended',
      buttonType: 'primaryChange',
      icon: 'bluestacks',
    }
  }
  // whether is an experience pre-registered app
  if (isOpenInBrowser) {
    return {
      actionText: button_text,
      actionCode: button_text,
      redirectCode: 'AppLauncher',
      buttonType: 'primaryChange',
      icon: 'bluestacks',
    }
  }

  const isAppDetailAccessable =
    app.isAppDetailAccessable && store.getState().system.countryCode !== 'JP'
  // get app startup way
  const firstPlay = platforms || game_play_preference[0]
  if (firstPlay === 'cloud') {
    const id =
      isAutoConfig && isAppDetailAccessable
        ? location === 'appDetailPage'
          ? 'playInBrowser'
          : 'viewDetails'
        : // 'playInstantly'
        'playInBrowser'
    return {
      actionText:
        intl &&
        intl.formatMessage({
          id,
        }),
      actionCode: 'playOnCloud',
      gameType: 'cloud',
      redirectCode: isAppDetailAccessable ? 'AppDetail' : 'AppLauncher',
      buttonType: id === 'viewDetails' ? 'primaryChange' : 'pinkChange',
      icon: 'nowgg',
    }
  }
  if (firstPlay === 'local') {
    const isOnViewDetails = location === 'common' && isAutoConfig && isAppDetailAccessable
    const buttonType = isOnViewDetails ? 'primaryChange' : 'successChange'
    // whether is installed
    if (isInstalled) {
      return {
        actionText:
          intl && intl.formatMessage({ id: isOnViewDetails ? 'viewDetails' : 'playOnAppPlayer' }),
        actionCode: 'playOnAppPlayer',
        gameType: 'simulator',
        redirectCode: isAppDetailAccessable ? 'AppDetail' : 'AppLauncher',
        buttonType,
        icon: 'bluestacks',
      }
    } else {
      if (isInstallCDN) {
        return {
          actionText:
            intl &&
            intl.formatMessage({
              id: isOnViewDetails ? 'viewDetails' : 'installInAppPlayer',
            }),
          actionCode: 'installInAppPlayer',
          gameType: 'simulator',
          redirectCode: isAppDetailAccessable ? 'AppDetail' : 'AppLauncher',
          buttonType,
          icon: 'bluestacks',
        }
      } else {
        return {
          actionText:
            intl &&
            intl.formatMessage({
              id: isOnViewDetails ? 'viewDetails' : 'installViaGooglePlay',
            }),
          actionCode: 'installViaGooglePlay',
          gameType: 'simulator',
          redirectCode: isAppDetailAccessable ? 'AppDetail' : 'AppLauncher',
          buttonType,
          icon: 'bluestacks',
        }
      }
    }
  }
  return {}
}
