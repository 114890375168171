/**
 * Fetch game list by topicId(will cached by topicId in redux)
 */
import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import Button from '@/components/common/Button/Button'
import GameGridLayout from '@/components/layout/gameGridLayout/Index'
import GamePageHeader from '@/components/common/Heading/GamePageHeader'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
import { STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES } from '@/tool/constant'
import { getTopGames } from '@/apis/gpt'
import SkeletonAppList from '@/skeletons/AppList'
import { useGridBaseCount } from '@/hook/useViewport'
import { gptTopGameTransformer } from '@/transformers'
const GptApps = () => {
  const gridCount = useGridBaseCount()

  const [apps, setApps] = useState([])

  // Fetch game list
  const { refetch, isSuccess, isFetching, isFetched } = useQuery(
    ['getTopGames',],
    () => getTopGames(),
    {
      onSuccess: (data) => {
        if (data.success) {
          setApps(gptTopGameTransformer(data.data))
        }
      },
    }
  )

  const titleJSX = (props) => {
    return <GamePageHeader
      title={'Top Games'}
      count={apps.length}
      showBack={false}
      {...props}
    />
  }

  if (isFetching) {
    return (
      <SkeletonAppList
        className={['breakScreen', 'margin-bottom-30']}
        imgPlaceholderClass="ratio-1-1"
        column={gridCount}
        headerRender={titleJSX}
        row={2}
        enable={isFetching}
      />
    )
  }

  return (
    <GameGridLayout
      overscrollBehavior='none'
      gameItemProps={{
        imgPlaceholderClass: 'ratio-1-1',
        showPlayBtn: true,
        showInstalledState: true,
        bannerLocation: STAT_APPS_PAGE_TYPEFOR_TOPIC_FROM_ANDROIDGAMES,
      }}
      list={apps}
      contentInnerClass={['breakScreen', 'margin-bottom-30']}
      columns={gridCount}
      headerRender={titleJSX({ className: 'breakScreen' })}
      scrollLoad={false}
      prefix={(list) => {
        if (list.length === 0 && isFetched) {
          return (
            <CommonHelpTip
              textTitle={isSuccess ? 'noFoundBySearch' : 'noInternetConnect'}
              imgSrc={isSuccess ? 'noResults' : 'noNetwork'}
              localSvg
              extra={() => (
                <Button type="primary" onClick={refetch}>
                  <FormattedMessage id="refresh" />
                </Button>
              )}
            />
          )
        }
      }}
    />
  )
}

export default GptApps
