/**
 * 轮播组件使用Home使用
 */

import React, { useCallback, useMemo, useRef } from 'react'
import classNames from 'classnames'
import Carousel from '@/components/Carousel/Index'
import GameListItem from '../common/GameListItem/GameListItem'
import SwiperSwitch from '@/components/adapter/swiperSwitch/Banner'
import './index.scss'
const GameCarouselSection = ({
  className,
  list,
  // slider组件props
  slideConfig = {},
  carouselKeyId,
  slidesPerView,
  slidesPerGroup,
  // GameListItem组件props
  showSubTitle = false,
  showCloudBtn,
  showPlayBtn,
  showPcBtn,
  showDeleteBtn,
  showTypeIcon,
  showDetailIcon,
  onItemClick = () => {},
  showInstalledState,
  showLikeIncon,
  showCsIcon = true,
  imgPlaceholderClass,
  imageUrlGet,
  bannerLocation,
  bootGameOnClick,
  hasHoverAction = true,
  onHoverEnter,
  // Image组件props
  lazyRootMargin = '0px',
  onImageLoad,
  onImageError,
  imageDisplayStrategy,

  renderItem = null,
  activeIndex = 0,
  setActiveIndex,
}) => {
  const initFocusIndex = useRef([activeIndex, 0])
  const itemProps = useMemo(() => {
    return {
      showSubTitle,
      showCsIcon,
      bootGameOnClick,
      imgPlaceholderClass,
      bannerLocation,
      showInstalledState,
      onImageError,
      onImageLoad,
      imageDisplayStrategy,
      lazyRootMargin,
      showTypeIcon,
      showLikeIncon,
      showPcBtn,
      showCloudBtn,
      showPlayBtn,
      showDeleteBtn,
      showDetailIcon,
      onItemClick,
      imageUrlGet,
      onHoverEnter,
      hasHoverAction,
    }
  }, [
    showSubTitle,
    showCsIcon,
    bootGameOnClick,
    imgPlaceholderClass,
    bannerLocation,
    showInstalledState,
    onImageError,
    onImageLoad,
    imageDisplayStrategy,
    lazyRootMargin,
    showTypeIcon,
    showLikeIncon,
    showPcBtn,
    showCloudBtn,
    showPlayBtn,
    showDeleteBtn,
    showDetailIcon,
    onItemClick,
    imageUrlGet,
    onHoverEnter,
    hasHoverAction,
  ])

  const renderChildJsx = useCallback(
    (slide, { index, focusIndex }) => {
      if (renderItem) {
        return renderItem({ slide, itemProps, index })
      }
      const _onHoverEnter = itemProps.onHoverEnter
        ? (app, swiper, swiperSlide) => {
            itemProps.onHoverEnter(app, index, swiper, swiperSlide)
          }
        : null

      const _onFocusWithin = (app, swiper, swiperSlide) => {
        itemProps.onHoverEnter(app, index, swiper, swiperSlide)
      }
      const [s, t] = focusIndex
      const tabIndex = s === index && t === 0 ? 0 : -1
      return (
        <GameListItem
          key={slide.package_name}
          onFocusWithin={_onFocusWithin}
          {...itemProps}
          onHoverEnter={_onHoverEnter}
          onClick={itemProps.onItemClick}
          itemIndex={index}
          game={slide}
          className={classNames([
            'game-carousel-section-carouselItem',
            { 'game-carousel-section-activeItem': activeIndex === index },
          ])}
          tabIndex={tabIndex}
          tabId={`${carouselKeyId}-${index}-${0}`}
        />
      )
    },
    [renderItem, itemProps, setActiveIndex, activeIndex]
  )
  const renderSwitchJSX = useCallback(({ nextElClassName, prevElClassName }) => {
    return (
      <SwiperSwitch
        nextElClassName={nextElClassName}
        prevElClassName={prevElClassName}
        className="game-carousel-section-homeBannerSwitch"
      />
    )
  }, [])
  if (list.length === 0) {
    return null
  }
  return (
    <Carousel
      carouselKeyId={carouselKeyId}
      list={list}
      className={['game-carousel-section', 'game-carousel-section-homeBanner', className]}
      slideConfig={slideConfig}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      renderHeader={renderSwitchJSX}
      initFocusIndex={initFocusIndex.current}
    >
      {renderChildJsx}
    </Carousel>
  )
}

export default GameCarouselSection
