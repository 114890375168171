import { useEffect, useState, useRef } from 'react'
import { containerValueFinder } from '@/tool'
// import getFirstFocusableElement from '@/tool/getFirstFocusableElement'

const defaultOptions = { keyboardKey: 'keyboardFocus', columns: 1, count: 0, initial: 0, direction: 'both' }

const Hook = (target, options = {}) => {
  const optionsRef = useRef(defaultOptions)
  const [focusIndex, setFocusIndex] = useState(optionsRef.current.initial)

  useEffect(() => {
    optionsRef.current = { ...defaultOptions, ...options, focusIndex }
  }, [options, focusIndex])

  // useEffect(() => {
  //   setTimeout(() => {
  //     const firstFocusableElement = getFirstFocusableElement(target.current)
  //     if (firstFocusableElement) {
  //       firstFocusableElement.focus()
  //     }
  //   }, 100)
  // }, [])


  useEffect(() => {
    const onKeydown = (evt) => {
      const { count, columns, keyboardKey, direction, focusIndex } = optionsRef.current
      if (direction === 'both' || direction === 'horizontal') {
        if (evt.code === 'ArrowRight') {
          if (focusIndex < count - 1) {
            setFocusIndex(focusIndex + 1)
            document.getElementById(`${keyboardKey}-${focusIndex + 1}`).focus()
          }
        }
        if (evt.code === 'ArrowLeft') {
          if (focusIndex > 0) {
            setFocusIndex(focusIndex - 1)
            document.getElementById(`${keyboardKey}-${focusIndex - 1}`).focus()
          }
        }
      }
      if (direction === 'both' || direction === 'vertical') {
        if (evt.code === 'ArrowDown') {
          if (focusIndex < count - 1 - columns) {
            setFocusIndex(index => index + columns)
            document.getElementById(`${keyboardKey}-${focusIndex + columns}`).focus()
          }
        }
        if (evt.code === 'ArrowUp') {
          if (focusIndex > columns - 1) {
            setFocusIndex(index => index - columns)
            document.getElementById(`${keyboardKey}-${focusIndex - columns}`).focus()
          }
        }
      }
    }
    const contentDom = containerValueFinder(target)
    contentDom?.addEventListener('keydown', onKeydown, false)
    return () => {
      contentDom?.removeEventListener('keydown', onKeydown)
    }
  }, [])

  return focusIndex
}

export default Hook
