import React from 'react'
import { useSelector } from 'react-redux'
import GamePageHeader from '@/components/common/Heading/GamePageHeader'
import CollectionGridLayout from '@/components/layout/collectionGridLayout/Index'
import { useGridBaseCount } from '@/hook/useViewport'
const Collections = () => {
  const gridCount = useGridBaseCount()
  const topics = useSelector((state) => state.app.topics)
  return (
    <CollectionGridLayout
      list={topics}
      columns={gridCount}
      className="breakScreen"
      headerRender={() => {
        return <GamePageHeader titleId="browseByCollection" />
      }}
    />
  )
}

export default React.memo(Collections)
