import React from 'react'
import { useSelector } from 'react-redux'
import Button from '@/components/common/Button/Button'
const PcButton = (props) => {
  const isMsi = useSelector((state) => state.system.isMsi)
  return <Button
    key="pcButton"
    type={isMsi ? 'whiteChange' : 'primaryChange'}
    icon="window"
    {...props}
  />
}

export default PcButton
