import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, matchPath, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'

import NoNetworkHelpTip from '@/components/adapter/NoNetworkHelpTip'
import Button from '@/components/common/Button/Button'

// actions
import { setInstalledApps, setLocalPerference, setInstalledPCGames } from '@/reducers/app/actions'
import { setClientUserConfig, setUserInfo } from '@/reducers/user/actions'
import {
  setPageLocation,
  setIsOffline,
  setFullscreenState,
} from '@/reducers/system/actions'
import { pushAppStack } from '@/reducers/appdetail/actions'

import useInstalledApps from '@/hook/useInstalledApps'
import useInstalledPcGames from '@/hook/useInstalledPcGames'
import usePerference from '@/hook/usePerference'
import useUserToken from '@/hook/useUserToken'
import useClientUserConfig from '@/hook/useClientUserConfig'
import useGmQuery from '@/hook/useGmQueryRemote'
import useNetwork from '@/hook/useNetwork'
import useGotoDetailForClient from '@/hook/useGotoDetailForClient'
import useRouter from '@/hook/useRouter'
import useFullscreenState from '@/hook/useFullscreenState'
import useTopicsActions from '@/hook/useTopicsActions'
import useRecommendGameActions from '@/hook/useRecommendGameActions'
import useAllApps from '@/hook/useAllApps'
import { createTrackId } from '@/tool/track'
import { memoryCache } from '@/hook/useScrollMemory'
import { on, off, qwebApi } from '@/apis/qwebApi'
import { useRef } from 'react'
window.matchPath = matchPath
// Page I18N Mapping(default:page)
const PageIntlMap = {
  gameCenter: 'androidGames',
  home: 'home',
  cloudGame: 'instantPlay',
}
const PageIntlMapProxy = new Proxy(PageIntlMap, {
  get(obj, key) {
    return obj[key] || 'page'
  },
})

const Setup = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();

  const pageLocation = useSelector((state) => state.system.location)

  const allApps = useAllApps()

  const pageLocationRef = useRef(pageLocation)
  useEffect(() => {
    pageLocationRef.current = pageLocation
  }, [pageLocation])
  // Search logic between client and web
  useGmQuery()

  // Register Router event
  useRouter()

  // Fullscreen state
  const state = useFullscreenState()
  useEffect(() => {
    dispatch(setFullscreenState(state))
  }, [dispatch, state])

  // Jump to app detail
  const onOpenDetail = useCallback(
    async (appDetail) => {
      if (appDetail) {
        // $$isClear:whether to clear appStack，default true;$$from:from where,default search_bar(client search bar);$$state: extra info
        const { $$isClear, $$from = 'search_bar', $$state = {}, ...app } = appDetail

        let _app = app
        let _isClear = $$isClear
        const packageName = appDetail.package_name
        if (!packageName) {
          return
        }
        /**
         * search_bar:从客户端search栏
         * my_game:从My Game数据来(type:pc,android)
         * agent:从bs5发送
         */
        switch ($$from) {
          case 'search_bar':
            createTrackId()
            dispatch(setPageLocation('home'))
            _isClear = true
            break
          case 'my_game':
            const { type } = $$state
            if (type === 'pc') {
              _app = { showPlayBtn: false, showPcBtn: false, showCloudBtn: false, ...app }
            } else if (type === 'android') {
              _app = allApps.find(game => game.package_name === packageName)
            }
            break
          case 'agent':
            _app = allApps.find(game => game.package_name === packageName) || { showPlayBtn: true, package_name: packageName }
            break
          default:

        }
        dispatch(pushAppStack({ app: _app, $$isClear: _isClear, $$from, $$state }))
        navigate(`/app/${packageName}`, {
          state: {
            from: $$from,
            ...$$state
          },
        })
      }
    },
    [dispatch, allApps]
  )

  useGotoDetailForClient(onOpenDetail)

  // local perference
  const localPerference = usePerference()
  useEffect(() => {
    dispatch(setLocalPerference(localPerference))
  }, [localPerference, dispatch])

  // client config (one_button_config)
  const clientUserConfig = useClientUserConfig()
  useEffect(() => {
    dispatch(setClientUserConfig(clientUserConfig))
  }, [dispatch, clientUserConfig])

  // User info
  const useInfo = useUserToken()
  useEffect(() => {
    dispatch(setUserInfo(useInfo))
  }, [useInfo, dispatch])

  // Update installed app(install and uninstall)---only package name
  const installedApps = useInstalledApps()
  useEffect(() => {
    dispatch(setInstalledApps(installedApps))
  }, [installedApps, dispatch])

  // Update installed pc games(install and uninstall)---only package name
  const installedPcGames = useInstalledPcGames()
  useEffect(() => {
    dispatch(setInstalledPCGames(installedPcGames))
  }, [installedPcGames, dispatch])

  // topics info

  useTopicsActions()

  // recommend game to qt
  useRecommendGameActions()

  // Page router event register for client
  useEffect(() => {
    const onRouterTo = ({ path, trigger }) => {
      // const isHomePage = ['/home', '/gameCenter'].includes(location.pathname)
      // if (pageLocationRef.current === path) {
      //   return
      // }
      dispatch(setPageLocation(path))
      if (trigger === 'client') {
        memoryCache.delete(`${path}-scroll-memory`)
        // window.$$switchToPath = true
        navigate({
          pathname: `/${path}`,
          search: 'animation=fade',
        })
      }
    }
    on('pageRouterTo', onRouterTo)
    return () => {
      off('pageRouterTo', onRouterTo)
    }
  }, [dispatch, location])

  // Get whether is online and last time status
  const [online, prevOnline] = useNetwork()
  useEffect(() => {
    dispatch(setIsOffline(online))
  }, [online, dispatch])

  // Whether to show offline page
  const showOfflinePage = useSelector((state) => state.system.showOfflinePage)
  useEffect(() => {
    if (showOfflinePage) {
      qwebApi({ event: 'changeBannerBackgroundImage' })
    }
  }, [showOfflinePage])

  if (online && !prevOnline) {
    setTimeout(() => {
      window.location.href = `/${pageLocation}`
    }, 5000)
  }
  if (showOfflinePage) {
    // Notify to refresh page
    const onRefreshClick = () => {
      window.location.href = `/${pageLocation}`
    }
    return (
      <NoNetworkHelpTip
        className="appNoNetworkContainer"
        values={{
          page: <FormattedMessage id={PageIntlMapProxy[pageLocation]} />,
        }}
        extra={() => (
          <Button
            type="primary"
            className="margin-top-20 font16 minw200"
            onClick={onRefreshClick}
            titleId="refresh"
          />
        )}
      />
    )
  }
  return null
}

export default Setup
