/**
 *
 */
import React, { useMemo, useRef } from 'react'
import classnames from 'classnames'
import Scrollload from '@/components/scrollload/Scrollload'
import useScrollBarActive from '@/hook/useScrollBarActive'
import ErrorFallback from '@/components/ErrorFallback/ErrorFallback'
import BaseHeader from '@/components/common/Heading/BaseHeader'

import './index.scss'
const GridLayout = ({
  className,
  contentInnerClass,
  columns,
  list,
  titleRender,
  headerRender,
  headerType = 'page',
  children,
  keyId,
  suffix = null,
  prefix = null,
  numberOfItems = columns,
  overscrollBehavior = 'auto',
  scrollLoad = true
}, ref) => {
  const scrollContainerRef = useRef(null)
  // 标题栏
  const titleRenderValue = useMemo(() => {
    let titleJSX = null
    if (titleRender) {
      titleJSX = typeof titleRender === 'function' ? titleRender() : titleRender
    }
    return titleJSX
  }, [titleRender])

  // Header栏
  const headerRenderValue = useMemo(() => {
    let headerJSX = null
    if (headerRender) {
      headerJSX = typeof headerRender === 'function' ? headerRender() : headerRender
    }
    return headerJSX
  }, [headerRender])

  // 列表样式
  const contentInnerStyl = useMemo(() => {
    return {
      gridTemplateColumns: `repeat(${columns}, minmax(10px, 1fr))`,
    }
  }, [columns])
  useScrollBarActive(scrollContainerRef, null, { enable: scrollLoad })
  const itemJsx = list => {
    return list.map((item, index) => {
      return (
        <div
          className="gridLayout-contentItem"
          key={item[keyId] + '_' + index || index}
          role="listitem"
        >
          {children(item, index)}
        </div>
      )
    })
  }
  return (
    <div className={classnames(['gridLayout-container', className])}>
      {headerRenderValue || titleRenderValue && <BaseHeader className="gridLayout-header" type={headerType}>{titleRenderValue}</BaseHeader>}
      <div className="gridLayout-content" ref={scrollContainerRef} style={{ overscrollBehavior }} role={(headerRenderValue || titleRenderValue) ? 'main' : 'group'}>
        <div className="gridLayout-prefix">{prefix && prefix(list)}</div>
        <div
          className={classnames(['gridLayout-contentInner', contentInnerClass])}
          style={contentInnerStyl}
          role="list"
          ref={ref}
        >
          <ErrorFallback>
            {scrollLoad ? <Scrollload
              list={list}
              scrollContainer={() => scrollContainerRef.current}
              numberOfItems={numberOfItems}
              render={itemJsx}
            /> : itemJsx(list)}
          </ErrorFallback>
        </div>
        <div className="gridLayout-suffix">{suffix && suffix(list)}</div>
      </div>
    </div>
  )
}

export default React.forwardRef(GridLayout)
