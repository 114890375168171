import produce from 'immer'
import * as types from './constant'

import { themeIsValid } from '@/tool/themeConfig'

const INIT_STATE = {
  userInfo: {},
  theme: 'lighter',
  clientUserConfig: {
    // App action config,default:3 (user select)
    one_button_config: 3,
  },
}
const userReducer = produce((state, action) => {
  const { type, payload } = action
  switch (type) {
    case types.SET_USER_INFO: {
      state.userInfo = payload
      break
    }
    case types.SET_THEME: {
      if (themeIsValid(payload)) {
        state.theme = payload
      }
      break
    }
    case types.CLIENT_USER_CONFIG: {
      state.clientUserConfig = payload
      break
    }
    default: {
    }
  }
}, INIT_STATE)

export default userReducer
