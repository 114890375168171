/**
 * Show all game list module(Support keyboard event)
 */

import React, { useRef } from 'react'
import ListLayout from '@/components/layout/listLayout/Index'
import useKeyboardFocus from '@/hook/useKeyboardFocus'
const KeyBoardListLayout = ({
  keyboardKey = 'common',
  children,
  ...gridLayoutProps
}) => {
  const contentRef = useRef(null)

  const focusIndex = useKeyboardFocus(contentRef, { keyboardKey, count: gridLayoutProps.list.length, direction: 'vertical' })
  return (
    <ListLayout
      {...gridLayoutProps}
      ref={contentRef}
    >
      {(item, index) => {
        const tabIndex = focusIndex === index ? 0 : -1
        return children({ tabIndex, gridItem: item, index, tabId: `${keyboardKey}-${index}` })
      }}
    </ListLayout>
  )
}

export default KeyBoardListLayout
