import React from 'react'
import { useSelector } from 'react-redux'
import CommonHelpTip from '@/components/common/HelpTip/CommonHelpTip'
const NoNetworkHelpTip = (props) => {
  const isMsi = useSelector((state) => state.system.isMsi)
  return <CommonHelpTip
    textTitle="noInternetConnect"
    textValue="offlineMessageBySearch"
    className="appNoNetworkContainer"
    imgSrc={isMsi ? "noNetworkMsi" : "noNetwork"}
    localSvg={true}
    {...props}
  />
}

export default NoNetworkHelpTip
